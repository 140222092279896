import React from "react";
import Container from "react-bootstrap/esm/Container";
import Menu from "./Menu";
import './App.css';
function About() {
    return (
    <div className="App">
        <Menu></Menu>
        <span>hi</span>
    </div>
    
    )
}
export default About;